.project-head{
    font-size: 2rem;
    font-weight: bold;
    color: #010080;
    text-align: center;
}
.divider {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.project-line {
    width: 5vw;
    height: 3px;
    margin-left: 1vw;
    margin-right: 1vw;
    background-color: #E61E28;
}

.project-square {
    width: 1vw;
    height: 1vw;
    background-color: white;
    border: 2px solid #010080;
}

.project-title1 {
    font-size: 1rem;
    line-height: 1.6;
    text-align: center;
   
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
}
.projects-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  @keyframes zoomIn {
    0% {
      transform: scale(0.1);
    }
    100% {
      transform: scale(1);
    }
  }
  
  .project-card {
    flex: 1;
    max-width: 30%; /* Adjust as needed */
    margin: 10px;
    padding: 10px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    text-align: center;
    overflow: hidden; /* Prevent overflow issues */
    position: relative; /* Ensure child elements respect parent's overflow */
    animation: zoomIn 1s ease-out; /* Apply the animation */
  }
  
  
  .project-image {
    width: 100%;
    height: auto;
    border-radius: 8px 8px 0 0;
  }
  
  .project-title {
    font-size: 20px;
    font-weight: bold;
    color: #333;
    margin: 15px 0 10px;
  }
  
  .project-description {
    font-size: 14px;
    color: #666;
    margin-bottom: 20px;
  }
  
  .read-more-btn {
    padding: 10px 20px;
    border: 1px solid #333;
    border-radius: 4px;
    background-color: #fff;
    cursor: pointer;
  }
  
  .read-more-btn:hover {
    background-color: #333;
    color: #fff;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .project-head{
        font-size: 2rem;
        font-weight: bold;
        color: #010080;
        margin-top: 3vw;
        text-align: center;
    }
    .divider {
        display: flex;
        align-items: center;
        justify-content: center;
       
    }
    
    .project-line {
        width: 5vh;
        height: 3px;
        margin-left: 1vw;
        margin-right: 1vw;
        background-color: #E61E28;
    }
    
    .project-square {
        width: 1vh;
        height: 1vh;
        background-color: white;
        border: 2px solid #010080;
    }
    
    .project-title1 {
        font-size: 1rem;
        line-height: 1.6;
        text-align: center;
       
        max-width: 700px;
        margin-left: auto;
        margin-right: auto;
    }
    .projects-row {
      flex-direction: column; /* Stack cards vertically on smaller screens */
      align-items: center; /* Center align cards */
    }
  
    .project-card {
      max-width: 100%; /* Full width for each card on mobile */
      margin-bottom: 20px; /* Add some space between stacked cards */
    }
  }
  