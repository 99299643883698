.hero {
    position: relative;
    height: 40vw;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;
    overflow: hidden;
}

.carousel .slide img {
    width: 100%;
    height: 40vw;
    object-fit: cover;
}

.hero .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
}

.hero-content {
    position: absolute;
    z-index: 1;
    width: 70%;
    padding: 0 20px;
}

.carousel-head {
    font-size: 3rem !important;
    font-weight: bold;
}

.hero .carousel-head  span {
    color: #E61E28;
}

.hero p {
    font-size: 1rem;
    max-width: 600px;
    margin: 0 auto;
}

.contact-info {
    display: flex;
    justify-content: center;
    padding: 20px;
    background-color: #f9f9f9;
}

.contact-info span {
    display: flex;
    align-items: center;
    font-size: 16px;
    margin: 0 10px;
}

.contact-info span svg {
    margin-right: 5px;
}

.home-card {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 20px;
    background-color: #010080; /* You can change this to any background color if needed */
    color: white; /* Set text color to white */
}

.home-card .quality,
.home-card .support {
    display: flex;
    width: 25vw;
    text-align: justify;
    color: white;
}

.quality-head {
    font-weight: bold;
    font-size: 1rem;
    margin-top: 1vw !important;
}

.support-head {
    font-weight: bold;
    font-size: 1rem;
    margin-top: 1vw !important;
}

.home-card .quality span,
.home-card .support span {
    font-size: 4rem; /* Adjust the icon size as needed */
    margin-right: 2vw;
}

.home-card .quality p,
.home-card .support p {
    margin: 0;
    padding: 0;
}

.home-button {
    background-color: #E61E28; /* You can change this to any button color if needed */
    padding: 1vw 1vw;
    font-weight: bold;
    cursor: pointer;
    color: white !important;
    text-align: center;
    text-decoration: none;
}

.home-card .home-button:hover {
    background-color: #D51C23; /* Slightly darker color for hover effect */
}

.why-choose-us {
    padding: 60px 20px;
    text-align: center;
    background-color: #fff;
}

.why-choose-us-head {
    font-size: 2rem !important;
    margin-bottom: 5px !important;
    font-weight: bold;
    color: #010080;
}

.divider {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    margin-left: 30vw;
    margin-right: 30vw;
    
}

.line {
    width: 3vw !important;
    height: 3px;
    margin-left: 1vw;
    margin-right: 1vw;
    background-color: #E61E28;
}

.square {
    width: 16px;
    height: 16px;
    background-color: white;
    border: 2px solid #010080;
}

.why-choose-us p {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 40px;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
}

.why-choose-card {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: 20px;
}

.service-item {
    text-align: center;
    width: 30vw;
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
    transition: border 0.3s ease;
    cursor: pointer;
    margin-right: 10px;
}

.service-item .icon {
    font-size: 2rem;
    color: #E61E28;
    margin-bottom: 10px;
}

.service-item-head {
    font-size: 2rem !important;
    font-weight: bold;
    margin-bottom: 0 !important;
}

.service-item p {
    font-size: 1rem;
}

.service-item:hover {
    border: 0.4vw solid #010080;
}

/* Media Queries for Responsive Design */
@media (max-width: 999px) {
    .home-card {
        flex-direction: column;
        align-items: center;
    }

    .home-card .quality,
    .home-card .support {
        width: 90%;
        margin-bottom: 20px;
    }
    .hero{
        height: 50vh;
    }
    .hero-content {
        position: absolute;
        z-index: 1;
        width: 90%;
        padding: 0 10px;
    }
    .carousel .slide img {
        width: 100%;
        height:50vh;
       
        object-fit: cover;
    }
    .carousel-head {
        font-size: 2rem !important;
        font-weight: bold;
    }
    .hero p {
        font-size: 0.7rem;
        max-width: 600px;
        justify-content: center;
        margin: 0 auto;
    }
    .why-choose-card {
        flex-direction: column;
        align-items: center;
        gap:2;
        width:90vw;
    }
    .service-item-head {
        font-size: 1rem !important;
        font-weight: bold;
        margin-bottom: 0 !important;
    }
    
    .service-item p {
        font-size: 1rem;
    }
    
    .service-item:hover {
        border: 0.4vw solid #010080;
    }

    .service-item {
        width:40vh;
        margin-bottom: 20px;
    }
}