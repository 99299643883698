@keyframes slideInLeft {
    0% {
      transform: translateX(-100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  @keyframes slideInRight {
    0% {
      transform: translateX(100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  .contact-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px;
    background-color: #f2e1dd;
  }
  
  .section-title {
    font-size: 24px;
    font-weight: bold;
    color: #010080;
    margin-bottom: 10px;
  }
  
  .title-decor {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .square {
    width: 1vw;
    height: 1vw;
    background-color: white;
   
  }
  
  .line {
    flex: 1;
    height: 2px;
    background-color: #E61E28
  }
  
  .location-section {
    flex: 1;
    max-width: 45%;
    margin-bottom: 20px;
    margin-left: 5vw;
    animation: slideInLeft 1s ease forwards;
  }
  
  .contact-form-section {
    flex: 1;
    max-width: 45%;
    margin-bottom: 20px;
    margin-right: 5vw;
    margin-left: 5vw;
    animation: slideInRight 1s ease forwards;
  }
  
  
  .map iframe {
    width: 100%;
    border: 0;
    height: 30vw;
    
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-control {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .form-buttons {
    display: flex;
    justify-content: space-between;
  }
  
  .btn-submit, .btn-reset {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .btn-submit {
    background-color:#E61E28 ;
    color: #fff;
  }
  
  /* Contact Cards Container */
.contact-cards {
    display: flex;
    justify-content: space-between;
    margin-left: 10vw;
    margin-right: 10vw;
    margin-top: 20px;
  }
  
.contact-card {
    flex: 2;
    background-color: #010080;
    padding: 10px;
    margin: 20px;
    transition: transform 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center items vertically */
    text-align: center; /* Center text */
  }
  
  .contact-card:hover {
    transform: translateY(-5px);
  }
  .icon{
    font-size: 3rem;
    color: white;
  }
  .email-head,
  .phone-head,
  .address-head{
    color: white;
    font-weight: bold;
  }
  .phone-number,
  .email-address,
  .address{
    color: white;
  }
  /* Responsive Styles */
  @media (max-width: 768px) {
    .location-section, .contact-form-section {
      max-width: 100%;
    }
    .contact-container {
       flex-direction: column-reverse;
    }
    
  .map iframe {
    width: 100%;
    border: 0;
    height: 35vh;
    
  }
  .contact-cards {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-left: 10vw;
    margin-right: 10vw;
    margin-top: 20px;
  }
  }
  