.about-us-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 10vw;
    padding-right: 10vw;
    padding-top: 5vw;
  }
  
  .about-us-content {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1200px;
    margin-bottom: 40px;
    position: relative; /* Added for positioning */
  }
  
  .about-us-img {
    width: 100%;
    height: 40vw;
    object-fit: cover;
    border-bottom: 1rem solid rgb(153, 22, 22);
    border-left: 1rem solid rgb(153, 22, 22);
    animation: zoomInImage 1s ease forwards;
  }
  
  @keyframes zoomInImage {
    0% {
      transform: scale(1.2) translateY(50%);
    }
    100% {
      transform: scale(1) translateY(0);
    }
  }
  
  .about-us-text {
    flex: 1;
    padding-left: 3vw;
  }
  
  .about-head {
    font-size: 2rem;
    font-weight: bold;
    color: #010080;
    animation: bounceInText 1s ease forwards;
  }
  
  @keyframes bounceInText {
    0% {
      transform: translateX(100%);
      opacity: 0;
    }
    70% {
      transform: translateX(-20%);
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  .about-section1 {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    margin-right: 20vw;
  }
  
  .about-line {
    flex: 1;
    height: 0.3vw;
    background-color: #e61e28;
    margin-left: 1vw;
    margin-right: 1vw;
  }
  
  .about-square {
    width: 16px;
    height: 16px;
    background-color: white;
    border: 0.3vw solid #010080;
  }
  
  .about-us-text p {
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  
  .services {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    background-color: #f8f9fa;
    width: 100%;
    padding: 20px 0;
  }
  
  .image-section {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }
  
  .image-section img {
    max-width: 100%;
    height: auto;
    max-height: 30vw;
    object-fit: cover;
    border-radius: 8px;
    margin-top: 7vw;
    animation: zoomInImage 1s ease forwards;
  }
  
  .content-section {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
  }
  
  .service-card {
    width: calc(50% - 10px);
    margin-bottom: 20px;
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .service-card .icon {
    font-size: 2rem;
    margin-bottom: 10px;
    color: #e61e28;
  }
  
  .service-head {
    font-weight: bold;
    margin: 0;
    font-size: 1rem;
    color: #010080;
  }
  
  .service-card p:last-child {
    margin: 0;
    color: #555;
  }
  
  /* Media Queries for Responsive Design */
  @media (max-width: 999px) {
    .about-us-content {
      flex-direction: column;
    }
  
    .about-us-img {
      order: -1;
      height: auto;
    }
  
    .about-head {
      font-size: 1.5rem !important;
      margin-bottom: 0px !important;
      margin-top: 1vh;
    }
  
    .about-us-text {
      padding-left: 0;
      text-align: justify;
    }
  
    .services {
      flex-direction: column;
      align-items: center;
      padding: 20px 10px;
    }
  
    .content-section {
      flex-direction: column;
      align-items: center;
    }
  
    .service-card {
      width: 90%;
      margin-top: 3vh;
    }
  
    .image-section {
      order: -1;
      width: 100%;
      display: flex;
      justify-content: center;
    }
  
    .image-section img {
      max-height: none;
      width: 90%;
      margin-top: 20px;
      animation: zoomInImage 1s ease forwards;
    }
  
    .about-head {
      animation: bounceInText 1s ease forwards;
    }
  }
  