header {
    background-color:#010080;
    color: white;
  }
  
  .contact-info {
    display: flex;
    justify-content: flex-start;
    padding: 10px 20px;
    font-size: 0.5rem;
    background-color:#010080 !important;
  }
  
  .contact-info span {
    display: flex;
   
    margin-left: 20px;
    color: white;
  }
  
  .contact-info span svg {
    margin-right: 5px;
  }
  
  .navbar-brand img {
    margin-left: 3vw;
  }
  
  .navbar-light .navbar-nav .nav-link {
    color: black;
    font-weight: bold;
  }
  
  /* Media query for mobile devices */
  @media (max-width: 768px) {
    .contact-info {
      font-size: 1rem;
      background-color: #333;
      padding: 10px;
    }
    .header-icon{
        font-size: 1.5rem;
    }
  }
  