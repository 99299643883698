/* Common styles for both laptop and mobile */
.footer {
  background-color: #f8f9fa;
  color: #010080;
  padding: 20px 0;
  font-family: Arial, sans-serif;
}

.footer-divider {
  display: flex;
  align-items: start;  
}

.footer-line {
  width: 5vw;
  height: 2px;
  margin-left: 0.5vw;
  margin-right: 1vw;
  margin-top: 0.4vw;
  background-color: #E61E28;
}

.footer-square {
  width: 1vw;
  height: 1vw;
  background-color: white;
  border: 2px solid #010080;
}

.footer-container {
  display: flex;
  justify-content: space-around;
  max-width: 1900px;
  margin: 0 auto;
  padding: 0 20px;
}

.footer-column {
  flex: 1;
  margin: 10px;
  margin-left: 5vw;
}

.contact-us-head,
.quick-links-head,
.followus-head {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 10px;
  color: #010080;
  margin-top: 10vw; /* Maintain margin for laptop view */
  text-align: start; /* Center headings for mobile */
}

.footer-column p,
.footer-column ul,
.footer-column a {
  font-size: 0.8rem;
  margin: 5px 0;
  text-align: start; /* Center text for mobile */
}

.footer-column a {
  color: #010080;
  text-decoration: none;
}

.footer-column a:hover {
  text-decoration: underline;
}

.footer-column ul {
  list-style-type: none;
  padding: 0;
}

.footer-column ul li {
  margin: 5px 0;
}

.footer-column .social-links {
  display: flex;
  flex-direction: column;
}

.footer-column .social-links li {
  margin: 5px 0;
}

.footer-bottom {
  text-align: center;
  padding: 10px;
  background-color: #010080;
  color: #f8f9fa;
}

.company-logo {
  max-width: 12vw;
  margin-bottom: 15px;
}

/* Media Query for Mobile Screens */
@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    padding: 10px; /* Add padding to footer container for spacing */
    text-align: start;
  }

  .contact-us-head,
  .quick-links-head {
    margin-top: 20px; /* Increase margin-top for headings on mobile */
    font-size: 1.5rem; /* Decrease font size for headings on mobile */
  }

  .company-logo {
    max-width: 50%; /* Adjust logo size for better fit on smaller screens */
  }
}
